// Centres 'refa' brand
@media (min-width: 768px) {
  .navbar-brand {
    position: relative;
    right: 6px;
  }
}

// filter buttons fixed-top margin reduced @ md displays
@media (max-width: 767px) {
  #filterBtns {
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 992px) {
  .card-columns {
    column-count: 2;
  }
}

// navbar-brand -> Gets rid of centering-adjustment for screen size < 'sm'
@media (max-width: 576px) {
  .navbar-brand {
    position: relative;
    right: 0;
    font-size: 12pt !important ;
  }
  .card-columns {
    column-count: 1;
  }
  .featured-artist-img {
    // height: 62vw !important;
  }
  #framing-text {
    width: 100% !important;
  }
  .banner {
    height: 62vw !important;
  }
}

.navbar-divider {
  margin: 0;
}

.sold-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 0px;

  .sold {
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 7px;
    top: 15px;
    right: 15px;
    width: 150px;
    background-color: #e43;
    color: #fff;
  }

  .on-hold {
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 7px;
    top: 15px;
    right: 15px;
    width: 150px;
    background-color: blue;
    color: #fff;
    font-size: 9pt;
  }
}

.banner {
  width: 100%;
  height: 34vw;
  object-fit: cover;
}

.featured-artist-img {
  width: 100%;
  // height: 20vw;
  object-fit: cover;
}

.ipad-img-fix {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgSizeMed {
  width: 75%;
  height: 100%;
  object-fit: cover;
}

.imgSizeSmall {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.banner-img {
  width: 100%;
  height: 22vw;
  object-fit: cover;
}

footer {
  .fab {
    font-size: 26pt;
  }
}

.jumbotron-fluid {
  background-image: url('/images/uploads/rolt-view-of-croaghpatrick.jpg');
  background-size: cover;
  background-position: center;
}

#results {
  background-color: white;
  position: absolute;
  overflow-y: scroll;
  top: 60px;
  right: 20px;
  z-index: 1;
  border: 2px solid aliceblue;
  border-radius: 3px;
  padding: 10px;

  @media (max-width: 576px) {
    top: 95px;
  }
}

.post img,
.exhibition img {
  @extend .img-fluid;
}

.lightbox-container a {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
