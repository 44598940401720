// $font-size-base: 0.9rem;

$jumbotron-padding: 1rem;

// $card-columns-count: 2;

$navbar-nav-link-padding-x: 1.2rem;

$myRed: darken(red, 15%);
$info: #d5f6f6;
