html,
body {
  overflow-x: hidden;
}

.bank-gothic,
h1,
h2,
h3,
h4,
h5,
.btn {
  font-family: 'BankGothic Md BT Medium' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.source-sans,
body {
  // font-family: 'Lato', sans-serif !important;
  // font-family: 'Jomolhari', serif !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}
